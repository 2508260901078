<template>
    <template v-if="formActive && hasCase">
    <a style='position: absolute; right: 15px;' target='_blank' :href="`https://worldcheck.refinitiv.com/#/accelus/fsp/%7B%22location%22%3A%22%3Flocale%3Den-US%23%2Ffsp%2Fcase%2F` + worldcheck.external_case_id + `%2Fview%2Fworldcheck%2F%257B%2522from%2522%253A%2522parent-case-manager%2522%257D%22%7D`">
        Open in World-Check One
    </a>
    <h4>{{$t('navigation.relations')}}</h4>
    <FormItem id="external_case_id" label="Case ID" v-model="worldcheck.external_case_id" :required="true" :disabled="true" />
    <FormItem id="name" :label="$t('overview.name')" v-model="worldcheck.relation.name" :required="true" :disabled="true" />
    <FormItem id="status" :label="$t('overview.status')" type="buttongroup" v-model="worldcheck.status" :options="statusOptions" :required="true" />
    <template v-if="worldcheck.worldcheck_case_lines.length">
        <br>
        <h4>Case lines</h4>
        <div class="row mb-0">
            <div class="col-auto col-form-label-sm" style="width:4%"></div>
            <div class="col-auto col-form-label-sm" style="width:4%">
                ID
            </div>
            <div class="col-sm-2 col-form-label-sm" style="width:12%">
                Match score
            </div>
            <div class="col-sm-2 col-form-label-sm" style="width:20%">
                {{ $tc('form.category', 2) }}
            </div>
            <div class="col-sm-3 col-form-label-sm">
                Match term
            </div>
            <div class="col-sm-3 col-form-label-sm">
                Primary name
            </div>
        </div>
        <div v-for="caseline in worldcheck.worldcheck_case_lines" :key="caseline.id" class="row">
            <div class="col-auto" style="width:4%"> 
                <FormItem id="name" type="checkbox" v-model="caseline.selected" @onclick="setSelectedCaseLine(caseline)" />
            </div>
            <div class="col-auto" style="width:4%"> {{caseline.id}} </div>
            <div class="col-sm-2" style="width:12%"> {{caseline.match_score + '%'}} </div>
            <div class="col-sm-2" style="width:20%"> {{caseline.categories}} </div>
            <div class="col-sm-3"> {{caseline.match_term}} </div>
            <div class="col-sm-3"> {{caseline.primary_name}} </div>
            <div class="col-auto p-0" style="width:8%">
                <button class="btn border border-dark" type="button" @click="setDetailsBox(caseline)"> 
                    Details...
                </button>
            </div>
        </div>
    </template>
    </template>
    <div v-else>No case found</div>
</template>

<script>
    export default {
        name: 'WorldcheckForm',
        props: ['itemData'],
        data() {
            return {
                worldcheck: {
                    'name': '',
                    'code': '',
                    'status': null,
                    'worldcheck_case_lines': [],
                    'relation': {}
                },
                manufacturers: [],
                statusOptions: [{id: "pending", name: this.$t('quote_status.pending')}, {id: "accepted", name: this.$t('quote_status.accepted')},{id: "declined", name: this.$t('quote_status.declined')}],
                submitted: false,
                loading: false,
                hasCase: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.worldcheck = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                this.invalid = {};
                let valid = this.worldcheck.status;
                const selectedLine = this.worldcheck.worldcheck_case_lines.find(line => line.selected === 1);
                this.worldcheck.selected_result_id = selectedLine ? selectedLine.id : null;
                return valid;
            },
            getData() {
                return this.worldcheck;
            },
            setData(data) {
                console.log(data);
                if(data.worldcheck_case !== undefined){
                    if(data.worldcheck_case){
                        this.worldcheck = data.worldcheck_case;
                        this.hasCase = true;
                        if(!data.worldcheck_case.relation){
                            this.worldcheck.relation = {id: null, name: data.search_query};
                        }
                        let cases = data.worldcheck_case.worldcheck_case_lines.length;
                        document.getElementById("detailsSpan").innerHTML = (": " + data.search_query + " (" + cases + " " + this.$tc('crm.hit', cases) + ")");
                    }
                }
                else{
                    this.worldcheck = data;
                    this.hasCase = true;
                }
            },
            reset() {
                this.worldcheck = {
                    'name': '',
                    'code': '',
                    'status': null,
                    'worldcheck_case_lines': [],
                    'relation': {}
                }
                this.loading = false
                this.invalid = {}
                this.hasCase = false;
            },
            setSelectedCaseLine(caseline){
                if(caseline.selected) return;
                this.worldcheck.worldcheck_case_lines.forEach(line => {
                    if(line.id != caseline.id)
                        line.selected = false
                });
            },
            setDetailsBox(caseline){
                const text = caseline.result_body;
                const box = document.getElementById('details-dialog');
                box.showModal();
                function logTheObj(obj) {
                    var ret = "";
                    for (var o in obj) {
                        var data = obj[o];
                        if (typeof data !== 'object') {
                            ret += "<tr><td>" + o + "</td><td>" + data + "</td></tr>";
                        } else {
                            ret += "<tr><td colspan='2'>" + o + "</td></tr>" + logTheObj(data);
                        }
                    }
                    return ret;
                }
                box.children[1].innerHTML = "<table>" + logTheObj(JSON.parse(text)) + "</table>";
                box.children[0].children[0].innerHTML = "Details: " + caseline.primary_name;
            },
        }
    }
</script>
<style scoped>
.fa-circle-question:hover {
    color: #666;
    cursor: pointer;
}
</style>